/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        
/* ==============================================
Google Maps
=============================================== */

  function initialize() {
  var myLatlng = new google.maps.LatLng(-26.028496, 28.028918); // Change your location Latitude and Longitude 
  var mapOptions = {
  zoom: 15,
  center: myLatlng
  }
  var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

// Disabled Map Scroll in Contact Page 
  map.setOptions({'scrollwheel': false});

// Black and White style for Google Map
  var styles = [
  {
  stylers: [
  { saturation: -100 }
  ]
  },{
  featureType: "road",
  elementType: "geometry",
  stylers: [
  { lightness: -8 },
  { visibility: "simplified" }
  ]
  },{
  featureType: "road",
  elementType: "labels",
  }
  ];
  map.setOptions({styles: styles});

// Google Map Maker 
  var marker = new google.maps.Marker({
  position: myLatlng,
  map: map,
  });
  }

  google.maps.event.addDomListener(window, 'load', initialize);


        /* ==============================================
Back to Top
=============================================== */

$(window).scroll(function(){
    if($(window).scrollTop() > 300){
      $("#back-to-top").fadeIn(600);
    } else{
      $("#back-to-top").fadeOut(600);
    }
  });
  
  $('#back-to-top, .back-to-top').click(function() {
      $('html, body').animate({ scrollTop:0 }, '1000');
      return false;
  });

    /* ==============================================
    Google Maps
    =============================================== */
    /*
      function initialize() {
      var myLatlng = new google.maps.LatLng(40.712784, -74.005941); // Change your location Latitude and Longitude 
      var mapOptions = {
      zoom: 15,
      center: myLatlng
      }
      var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

    // Disabled Map Scroll in Contact Page 
      map.setOptions({'scrollwheel': false});

    // Black and White style for Google Map
      var styles = [
      {
      stylers: [
      { saturation: -100 }
      ]
      },{
      featureType: "road",
      elementType: "geometry",
      stylers: [
      { lightness: -8 },
      { visibility: "simplified" }
      ]
      },{
      featureType: "road",
      elementType: "labels",
      }
      ];
      map.setOptions({styles: styles});

    // Google Map Maker 
      var marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      });
      }

      google.maps.event.addDomListener(window, 'load', initialize);

      */

        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
